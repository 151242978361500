import isNotAuth from '@/router/middleware/auth/isNotAuth'
import notFound from '@/router/middleware/notFound'
import auth from '@/router/middleware/auth/auth'
import accountInReview from '@/router/middleware/auth/accountInReview'
import isAccountDisabled from '@/router/middleware/auth/isAccountDisabled'

import { ROUTES } from '@/constants/routes'
import accountIsNotReview from '@/router/middleware/auth/accountIsNotReview'

export const BASE_ROUTES = [
    {
        path: '/auth',
        name: ROUTES.auth,
        redirect: { name: ROUTES.login },
        component: () => import('@/views/auth/Auth.vue'),
        children: [
            {
                path: 'login',
                name: ROUTES.login,
                meta: {
                    layout: 'empty', middleware: isNotAuth, title: 'Dialics Affiliate Program', reference: ROUTES.auth,
                },
                component: () => import('@/views/auth/AuthLogin/AuthLogin.vue'),
            },
            {
                path: 'reset-password',
                name: ROUTES.authReset,
                meta: { layout: 'empty', middleware: isNotAuth, reference: ROUTES.auth },
                component: () => import('@/views/auth/AuthResetPassword/AuthResetPassword.vue'),
            },
            {
                path: 'reset-password/:token',
                name: ROUTES.authSetNewPassword,
                meta: { layout: 'empty', middleware: isNotAuth, reference: ROUTES.auth },
                component: () => import('@/views/auth/AuthSetNewPassword/AuthSetNewPassword.vue'),
            },
        ],
    }, {
        path: '/verification',
        name: ROUTES.verification,
        meta: { layout: 'main', middleware: [auth, accountIsNotReview], reference: ROUTES.verification },
        component: () => import('@/views/auth/Verification/Verification.vue'),
    }, {
        path: '/signup',
        name: ROUTES.register,
        meta: {
            layout: 'empty', middleware: isNotAuth, title: 'Dialics Affiliate Program Signup', reference: ROUTES.register,
        },
        component: () => import('@/views/auth/Register/Register.vue'),
    }, {
        path: '/reporting',
        name: ROUTES.reporting,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.reporting },
        component: () => import('@/views/reporting/Reporting.vue'),
    }, {
        path: '/referrals',
        name: ROUTES.referral,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.referral },
        component: () => import('@/views/referral/Referral.vue'),
    }, {
        path: '/withdrawal',
        name: ROUTES.withdrawal,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.withdrawal },
        component: () => import('@/views/withdrawal/Withdrawal.vue'),
    }, {
        path: '/withdrawal-platforms',
        name: ROUTES.withdrawalPlatform,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.withdrawalPlatform },
        component: () => import('@/views/withdrawal-platforms/WithdrawalPlatform.vue'),
    }, {
        path: '/withdrawal-platforms/:slug',
        name: ROUTES.detailWithdrawalPlatform,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.detailWithdrawalPlatform },
        component: () => import('@/views/withdrawal-platforms/DetailWithdrawalPlatform.vue'),
    }, {
        path: '/profile',
        name: ROUTES.profile,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.profile },
        component: () => import('@/views/settings/profile/Profile.vue'),
    }, {
        path: '/account-setting',
        name: ROUTES.accountSetting,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.accountSetting },
        component: () => import('@/views/settings/account-setting/AccountSetting.vue'),
    }, {
        path: '/password',
        name: ROUTES.password,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.password },
        component: () => import('@/views/settings/password/Password.vue'),
    }, {
        path: '/two-factor-auth',
        name: ROUTES.twoFactorAuth,
        meta: { layout: 'main', middleware: [auth, accountInReview], reference: ROUTES.twoFactorAuth },
        component: () => import('@/views/settings/two-factor-auth/TwoFactorAuth.vue'),
    }, {
        path: '/account-disabled',
        name: ROUTES.accountDisabled,
        meta: { layout: 'empty', middleware: [auth, accountInReview, isAccountDisabled], reference: ROUTES.accountDisabled },
        component: () => import('@/views/auth/AccountDisabled/AccountDisabled.vue'),
    }, {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        meta: { layout: 'empty', middleware: notFound },
        component: () => import('@/views/NotFound.vue'),
    },
]
