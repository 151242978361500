import UserIcon from '@/components/icons/navigations/UserIcon.vue'
import AccountSettingsIcon from '@/components/icons/navigations/AccountSettingsIcon.vue'
import SecurityIcon from '@/components/icons/navigations/SecurityIcon.vue'
import LogoutIcon from '@/components/icons/navigations/LogoutIcon.vue'

import { ROUTES } from '@/constants/routes'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'

const MENU_ACCOUNT = [
    {
        id: 1,
        name: 'Profile',
        routeName: ROUTES.profile,
        icon: () => UserIcon,
    },
    {
        id: 2,
        name: 'Account Settings',
        routeName: ROUTES.accountSetting,
        icon: () => AccountSettingsIcon,
    },
    {
        id: 3,
        name: 'Security',
        icon: () => SecurityIcon,
        isOpenSub: false,
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Password',
                routeName: ROUTES.password,
            },
            {
                name: 'Two-Factor Auth',
                routeName: ROUTES.twoFactorAuth,
            },
        ],
    },
    {
        id: 5,
        name: 'Logout',
        action: NAVIGATION_ACTIONS.logout,
        icon: () => LogoutIcon,
    },
]

export {
    MENU_ACCOUNT,
}
