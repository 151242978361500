<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.73047 16.8921V7.10547C2.73047 6.41511 3.29011 5.85547 3.98047 5.85547H20.0198C20.7102 5.85547 21.2698 6.41511 21.2698 7.10547V16.8921C21.2698 17.5824 20.7102 18.1421 20.0198 18.1421H3.98047C3.29011 18.1421 2.73047 17.5824 2.73047 16.8921Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M3.58496 9.35938L20.6045 9.35937" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5.34961 12.5254H8.96633" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5.34961 15.4102H17.3319" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script setup>

</script>