import { defineStore } from 'pinia'

import { getCountries, getTimezones } from '@/api/app-routes'
import { getCurrentTimezone } from '@/helpers/timezone-helper'

import { useDefaultStore } from '@/store/defaultStore'

export const useAppStore = defineStore('app', {
    state: () => ({
        routeIsLoading: false,
        themeTimerId: null,
        countries: [],

        timezonesLoading: false,
        timezones: [],
        currentTimezone: getCurrentTimezone() || 45,

        manageLayoutScrollTop: null,
    }),

    getters: {
        timezoneOffset() {
            return this.getTimezoneOffsetById(this.currentTimezone)
        },
    },

    actions: {
        setRouteLoading(loading) {
            this.routeIsLoading = loading
        },

        setManageLayoutScrollTop(elem) {
            this.manageLayoutScrollTop = elem
        },

        setCurrentTimezone(id) {
            this.currentTimezone = id
        },

        getTimezoneOffsetById(id) {
            const timezone = this.timezones.find((timezone) => timezone.id === id)

            return timezone ? timezone.php : 'UTC'
        },

        changeTimerId(id) {
            this.themeTimerId = id
        },

        async loadTimezones() {
            if (Array.isArray(this.timezones) && this.timezones.length) {
                return
            }

            this.timezonesLoading = true

            const { success, payload, message } = await getTimezones()

            if (success) {
                this.timezones = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }

            this.timezonesLoading = false
        },

        async loadCountries() {
            if (Array.isArray(this.countries) && this.countries.length) {
                return
            }

            const { success, payload, message } = await getCountries()

            if (success) {
                this.countries = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },
    },
})
