export const ROUTES = {
    auth: 'Auth',
    login: 'AuthLogin',
    authReset: 'AuthReset',
    authSetNewPassword: 'AuthSetNewPassword',
    register: 'Register',
    reporting: 'Reporting',
    reviewAccount: 'ReviewAccount',
    referral: 'Referral',
    withdrawal: 'Withdrawal',
    withdrawalPlatform: 'WithdrawalPlatform',
    detailWithdrawalPlatform: 'DetailWithdrawalPlatform',
    gettingStart: 'GettingStart',
    verification: 'Verification',
    profile: 'Profile',
    accountSetting: 'AccountSetting',
    password: 'Password',
    twoFactorAuth: 'TwoFactorAuth',
    accountDisabled: 'AccountDisabled',
}
