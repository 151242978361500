<template>
    <div class="flex items-center gap-x-2">
        <DollarHeaderIcon class="text-gray-200 dark:text-white"/>

        <div class="text-xs font-medium text-green-600 dark:text-green">{{ accountBalance }}</div>
    </div>
</template>

<script setup>
import {
    computed,
} from 'vue'

import DollarHeaderIcon from '@/components/icons/DollarHeaderIcon.vue'

import { currency } from '@/helpers/app-helper'

const props = defineProps({
    balance: {
        type: Number,
        default: null,
    },
})

const accountBalance = computed(() => currency(props.balance))

</script>
