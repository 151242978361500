import { ROUTES } from '@/constants/routes'

export default function accountInReview({ next, router, store }) {
    const { in_review } = store

    if (in_review) {
        return router.push({ name: ROUTES.verification })
    }

    next()
}
