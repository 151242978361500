<template>
    <div class="mx-0.5 my-0.5 grid gap-y-1">
        <template
            v-for="route in menu"
            :key="route.id"
        >
            <CustomRouterLink
                :class="menuStyle(route)"
                :style="customStyle(route)"
                :route="route"
                :simpleRote="!route.subMenu && !!route.routeName"
                @click.stop="onOpenSubMenu(route)"
            >
                <div
                    class="flex items-center gap-x-3 group"
                    :class="[route.isOpenSub && 'mb-3.25']"
                >
                    <div class="w-6">
                        <component :is="route.icon()" />
                    </div>

                    <div class="text-3.25">
                        {{ route.name }}
                    </div>

                    <div
                        v-if="route.subMenu"
                        class="w-6 h-6 flex items-center justify-center ml-auto"
                    >
                        <ArrowSelect
                            class="w-3 transform duration-100"
                            :class="{ 'rotate-180': route.isOpenSub }"
                        />
                    </div>
                </div>

                <RoutingSideBarBig
                    :route="route"
                    :checkCurrentLink="checkCurrentLink"
                    wrapperSubMenu="ml-3"
                    @onLinkCLick="onOpenCloseAccountMenu"
                />
            </CustomRouterLink>
        </template>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import CustomRouterLink from '@/components/navigation/CustomRouterLink.vue'
import RoutingSideBarBig from '@/components/navigation/RoutingSideBar/RoutingSideBarBig.vue'
import ArrowSelect from '@/components/icons/arrow/ArrowSelect.vue'

import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { MENU_ACCOUNT } from '@/constants/menuAccount'

const emits = defineEmits(['onOpenCloseAccountMenu'])

const authorizationStore = useAuthorizationStore()

const route = useRoute()

const menu = ref(MENU_ACCOUNT)

function menuStyle(route) {
    const { isOpenSub, subMenu } = route

    const active = checkCurrentLink(route, subMenu)

    const classes = ['text-3.25 px-3 py-1.5 rounded-1.25 text-xs transition-all cursor-pointer relative']

    if (!subMenu) {
        classes.push('flex')
    }

    if (isOpenSub) {
        classes.push(
            'dark:hover:bg-transparent space-y-1',
            active ? 'text-gray-370 dark:text-white' : 'text-gray-370 dark:text-white',
        )
    } else {
        classes.push(
            'hover:bg-purple-70 dark:hover:bg-blue-770',
            active ? 'border-purple dark:border-transparent text-purple dark:text-purple-140' : 'border-transparent text-gray-370 dark:text-white',
        )
    }

    return classes.join(' ')
}

function customStyle({ isOpenSub, subMenu }) {
    if (subMenu) return { height: isOpenSub ? `${50 + (32 * subMenu.length) + (4 * (subMenu.length - 1))}px` : '34px' }
}

function checkCurrentLink(parent, subMenu) {
    const { routeName, isFirstLoad } = parent

    if (subMenu) {
        const isActive = subMenu.some((el) => el.routeName === route.name)
        if (isActive && isFirstLoad) {
            parent.isOpenSub = isActive
            parent.isFirstLoad = false
        }
        return isActive
    }
    return route.name === routeName
}

function onOpenCloseAccountMenu() {
    emits('onOpenCloseAccountMenu')
}

function onOpenSubMenu(route) {
    const { isOpenSub, subMenu } = route

    if (subMenu) {
        route.isOpenSub = !isOpenSub
    } else {
        onOpenCloseAccountMenu()
    }

    const foundElement = menu.value.find((el) => el.name !== route.name && el.isOpenSub)

    if (foundElement) {
        foundElement.isOpenSub = false
    }

    navigationActionHandler(route)
}

function navigationActionHandler(route) {
    if (!route.hasOwnProperty('action')) {
        return
    }

    switch (route.action) {
    case NAVIGATION_ACTIONS.logout:
        authorizationStore.logout()
    }
}
</script>
