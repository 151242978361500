<template>
    <div
        class="fixed flex flex-col justify-between bg-white-300 dark:bg-black-370 h-full pt-2.5 pb-5 transition-width custom-scroll-y"
        :class="[
            mini ? 'w-side-bar-main-mini' : 'w-side-bar-main',
            showMobileSideBars ? 'z-30' : '-left-offset-side-bar-main min-mb:left-0'
        ]"
        v-touch:swipe.left="swipeLeft"
    >
        <div>
            <SidebarTop
                :mini="mini"
                @changeMini="changeMini"
            />

            <CustomDivider
                class="mt-5 mb-7 mx-4 max-mob:mx-2"
                color="border-gray-100 dark:border-gray-760"
            />

            <div
                v-if="!mini"
                class="app-text-xs px-4 mb-3 whitespace-nowrap"
            >
                AFFILIATE PROGRAM
            </div>

            <RoutingSideBar
                :mini="mini"
                :showMobileSideBars="showMobileSideBars"
                :items="MENU_ROUTES"
            />
        </div>

        <div>
            <RoutingSideBar
                :mini="mini"
                :showMobileSideBars="showMobileSideBars"
                :items="BOTTOM_MENU_ROUTES"
            />

            <CustomDivider
                class="m-4 max-mob:mx-2"
                color="border-gray-100 dark:border-gray-760"
            />

            <SideBarAccount :mini="mini"/>
        </div>
    </div>
</template>

<script setup>

import SidebarTop from '@/components/navigation/Sidebar/SidebarTop.vue'
import CustomDivider from '@/components/divider/CustomDivider.vue'
import RoutingSideBar from '@/components/navigation/RoutingSideBar/RoutingSideBar.vue'
import SideBarAccount from '@/components/navigation/Sidebar/SideBarAccount.vue'

import { BOTTOM_MENU_ROUTES, MENU_ROUTES } from '@/constants/menu'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    showMobileSideBars: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:showMobileSideBars', 'update:mini'])

function changeMini() {
    emits('update:mini', !props.mini)
}

function swipeLeft() {
    emits('update:showMobileSideBars', false)
}
</script>
