import { defineStore } from 'pinia'

import {
    checkUserAccess, getLogout, changePassword, sendEmailForResetPassword,
} from '@/api/auth-routes'
import { removeAccessToken } from '@/helpers/auth-helper'

import { useDefaultStore } from '@/store/defaultStore'

export const useAuthorizationStore = defineStore('authorizationStore', {
    state: () => ({
        actionLoading: false,
        in_review: null,
        blocked: null,
        user: null,
        permissions: null,
    }),

    actions: {
        saveUserData({ user, in_review, blocked }) {
            this.in_review = in_review
            this.blocked = blocked
            this.user = user
        },

        updateUser(user) {
            this.user = user
        },

        async getCheckUserAccess() {
            const { success, payload, message } = await checkUserAccess()

            if (success) {
                this.saveUserData(payload)
            } else {
                this.$reset()
                removeAccessToken()
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async logout() {
            const defaultStore = useDefaultStore()

            const { success, message } = await getLogout()

            if (success) {
                this.$reset()
                removeAccessToken()
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToUpdatePassword(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await changePassword(formData)

            if (success) {
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToSendEmailForResetPassword(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await sendEmailForResetPassword(formData)

            if (success) {
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },
    },
})
