import { ROUTES } from '@/constants/routes'

export default function accountIsNotReview({ next, router, store }) {
    const { in_review } = store

    if (!in_review) {
        return router.push({ name: ROUTES.reporting })
    }

    next()
}
