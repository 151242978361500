<template>
    <component
        :is="layout"
        ref="appRef"
    />

    <notifications
        class="min-md:mr-5 min-md:mt-5 z-110 cursor-pointer"
        :max="3"
        width="90%"
        :position="positionNotification"
    />
</template>

<script setup>
import {
    ref, computed, watch, markRaw, provide, onMounted, onUnmounted,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotification } from '@kyvg/vue3-notification'
import { useResizeObserver } from '@vueuse/core'

import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'

import { stopPropagationForNotification } from '@/helpers/app-helper'
import { setTheme } from '@/helpers/theme-local-storage-helper'

import { ROUTES } from '@/constants/routes'
import { viewPorts } from '@/constants/viewPorts'
import { useDefaultStore } from '@/store/defaultStore'
import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

const route = useRoute()
const router = useRouter()
const { notify } = useNotification()

const appStore = useAppStore()
const defaultStore = useDefaultStore()
const authorizationStore = useAuthorizationStore()

const currentViewSize = ref(0)
const appRef = ref(null)

const layout = computed(() => {
    const layout = `${route.meta.layout || 'empty'}-layout`

    const lookup = {
        'main-layout': MainLayout,
        'empty-layout': EmptyLayout,
    }

    return lookup.hasOwnProperty(layout) ? markRaw(lookup[layout]) : null
})

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

const positionNotification = computed(() => (isMbView.value ? 'bottom center' : 'top right'))

const user = computed(() => authorizationStore.user)

useResizeObserver(appRef, (entries) => {
    const [entry] = entries
    const { contentRect } = entry
    const { width } = contentRect

    const name = Object.keys(viewPorts).find((name) => viewPorts[name] >= width)
    currentViewSize.value = viewPorts[name]
})

provide('currentViewSize', currentViewSize)

watch(() => defaultStore.message, (text) => {
    if (text) {
        notify({ type: defaultStore.type, text, duration: defaultStore.duration })
        defaultStore.clearMessage()
    }
})

watch(user, (user) => {
    if (!user) {
        router.push({ name: ROUTES.login })
    }
})

onMounted(() => {
    appStore.loadTimezones()
    appStore.loadCountries()
    stopPropagationForNotification()

    appStore.changeTimerId(setTheme())
})

onUnmounted(() => {
    clearTimeout(appStore.themeTimerId)
})
</script>
