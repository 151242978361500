function _getTimezoneFromLocalStorage() {
    return localStorage.getItem('account_timezone')
}

function getCurrentTimezone() {
    const timezone = _getTimezoneFromLocalStorage()

    return timezone ? Number(timezone) : null
}

function setTimezoneInLocalStorage(timezoneId) {
    localStorage.setItem('account_timezone', timezoneId)
}

export { getCurrentTimezone, setTimezoneInLocalStorage }
