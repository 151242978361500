function getTippyTable(content, maxWidth = 160) {
    return {
        content,
        theme: 'table',
        placement: 'bottom',
        maxWidth,
    }
}

function getTippySidebar(content) {
    return {
        content,
        placement: 'right',
        theme: 'menu',
    }
}

export {
    getTippyTable,
    getTippySidebar,
}
