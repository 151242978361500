import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'
import VClickOutside from 'click-outside-vue3'
import VueTippy from 'vue-tippy'
import Vue3TouchEvents from 'vue3-touch-events'
import router from '@/router'
import App from '@/App.vue'
import vEsc from '@/directives/v-esc'

import '@/assets/main.css'

const pinia = createPinia()

const app = createApp(App)

app.directive('esc', vEsc)

pinia.use(({ store }) => {
    store.router = markRaw(router)
})

app.use(pinia)
app.use(router)
app.use(Notifications)
app.use(VClickOutside)
app.use(
    VueTippy,
    {
        directive: 'tippy',
        component: 'tippy',
        componentSingleton: 'tippy-singleton',
        defaultProps: {
            theme: 'dark',
            placement: 'auto-end',
            allowHTML: true,
            delay: 0,
            arrow: false,
            hideOnClick: false,
            touch: false,
            offset: [0, 12],
            maxWidth: 'none',
        },
    },
)
app.use(Vue3TouchEvents)
app.mount('#app')
