import HelpIcon from '@/components/icons/navigations/HelpIcon.vue'
import ReferralIcon from '@/components/icons/navigations/ReferralIcon.vue'
import WithdrawalIcon from '@/components/icons/navigations/WithdrawalIcon.vue'
import ReportingIcon from '@/components/icons/navigations/ReportingIcon.vue'

import { ROUTES } from '@/constants/routes'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'

const MENU_ROUTES = [
    {
        id: 1,
        name: 'Reporting',
        routeName: ROUTES.reporting,
        icon: () => ReportingIcon,
    },
    {
        id: 2,
        name: 'Referrals',
        routeName: ROUTES.referral,
        icon: () => ReferralIcon,
    },
    {
        id: 3,
        name: 'Withdrawal',
        routeName: ROUTES.withdrawal,
        icon: () => WithdrawalIcon,
    },
]

const BOTTOM_MENU_ROUTES = [
    {
        id: 4,
        name: 'Help',
        action: NAVIGATION_ACTIONS.help,
        icon: () => HelpIcon,
    },
]

export {
    MENU_ROUTES,
    BOTTOM_MENU_ROUTES,
}
