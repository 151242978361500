<template>
    <div class="flex h-full w-full">
        <AppBar
            :mini="mini"
            :marginClass="marginClass"
            @showSideBars="showSideBars"
        />

        <Sidebar
            v-model:mini="mini"
            v-model:showMobileSideBars="showMobileSideBars"
        />

        <div
            class="w-full overflow-hidden transition-spacing bg-white dark:bg-blue-800"
            :class="marginClass"
            @click="hideSideBars"
        >
            <div
                class="relative w-full transition-all"
                :class="mainClass"
            >
                <router-view v-slot="{ Component, route }">
                    <transition name="fade">
                        <div class="h-full" :key="route.name">
                            <component :is="Component" />
                        </div>
                    </transition>
                </router-view>

                <transition name="fade">
                    <div
                        v-if="appStore.routeIsLoading"
                        class="absolute top-0 w-full h-full flex items-center justify-center z-120 bg-black/4"
                    >
                        <MainLoader />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ref, computed, watch, onMounted, provide, inject,
} from 'vue'

import { useRoute } from 'vue-router'

import Sidebar from '@/components/navigation/Sidebar/Sidebar.vue'
import AppBar from '@/components/navigation/AppBar.vue'
import MainLoader from '@/components/mainLoader/MainLoader.vue'

import { getBarsPositions, setBarsPositions, addEventListenerStorage } from '@/helpers/app-local-storage-helper'

import { viewPorts } from '@/constants/viewPorts'
import { useAppStore } from '@/store/appStore'
import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'

const appStore = useAppStore()
const breadCrumbsStore = useBreadCrumbsStore()

const currentViewSize = inject('currentViewSize')

const route = useRoute()

const mini = ref(false)
provide('mini', mini)

const showMobileSideBars = ref(false)

const marginClass = computed(() => [
    mini.value && 'min-mb:ml-from-side-bars-min',
    !mini.value && 'min-mb:ml-from-side-bars-max',
])

const mainClass = computed(() => (breadCrumbsStore.showMobileBC && isMbView
    ? 'mt-from-app-bar-with-bc h-main-container-with-bc'
    : 'mt-from-app-bar h-main-container'))

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

function getSideBarsPositions() {
    const positions = getBarsPositions()

    mini.value = positions.mini
}

function showSideBars() {
    mini.value = false

    showMobileSideBars.value = true
}

function hideSideBars() {
    showMobileSideBars.value = false
}

watch(() => ({ mini: mini.value }), (positions) => {
    setBarsPositions(positions)
})

watch(route, hideSideBars)

onMounted(() => {
    getSideBarsPositions()
    addEventListenerStorage()
})
</script>
