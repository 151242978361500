import { defineStore } from 'pinia'
import { getUserHeader } from '@/api/headers-routes'
import { useDefaultStore } from '@/store/defaultStore'

export const useHeaderStore = defineStore('headerStore', {
    state: () => ({
        loading: true,
        balance: null,
        invitedUsers: null,
        referralLink: null,

    }),
    actions: {
        async goToLoadHeader() {
            const defaultStore = useDefaultStore()
            this.loading = true

            const { success, payload, message } = await getUserHeader()

            if (success) {
                this.parsePayload(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        parsePayload({ balance, referrals, link }) {
            this.balance = balance
            this.invitedUsers = referrals
            this.referralLink = link
        },
    },
})
