function currency(value = 0, currency = 'USD') {
    const newValue = value || 0
    const roundedValue = +newValue.toFixed(getMinimumFractionDigits(newValue))

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        minimumFractionDigits: getMinimumFractionDigits(roundedValue),
        maximumFractionDigits: 5,
        currency,
    }).format(roundedValue)
}

function getMinimumFractionDigits(value) {
    const fractionDigits = (value.toString().split('.')[1] || '').length
    return Math.min(fractionDigits, 5)
}

function checkFormValidity(formData) {
    return Object.keys(formData).every((name) => !formData[name].hasOwnProperty('valid') || formData[name].valid)
}

function onlyNumbers(event) {
    const regex = new RegExp('^[0-9]+$')
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
        event.preventDefault()
        return false
    }
}

function collectFormData(formData) {
    return Object.keys(formData).reduce((data, name) => {
        data[name] = formData[name].value
        return data
    }, {})
}

function stopPropagationForNotification() {
    const el = document.querySelector('.vue-notification-group')

    el.addEventListener('click', (e) => {
        e.stopPropagation()
    })
}

function scrollToTopById(id) {
    const container = document.querySelector(id)

    if (container) {
        container.scrollTop = 0
    }
}

function copyInStash(text) {
    const tempInput = document.createElement('input')
    tempInput.value = text
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
}

function splitString(str) {
    const items = str.split('')

    const newArray = items.map((el, index) => {
        if (index !== 0 && el === el.toUpperCase()) {
            return ` ${el}`
        }

        return el
    })

    return newArray.join('')
}

function checkEqualsArrays(newArray, oldArray) {
    return JSON.stringify(localSort(newArray)) === JSON.stringify(localSort(oldArray))
}

function localSort(items, type = 'string', sortBy = null, isDesc = false) {
    // if (!sortBy) {
    //     return items
    // }

    return copyArray(items).sort((a, b) => _customSorting(sortBy ? a[sortBy] : a, sortBy ? b[sortBy] : b, isDesc, type))
}

function _customSorting(a, b, desc, type) {
    switch (type) {
    case 'boolean':
        return _booleanSorting(a, b, desc)
    case 'number':
        return _numericSorting(a, b, desc)
    case 'time':
        return _timeSorting(a, b, desc)
    case 'date':
        return _dateSorting(a, b, desc)
    case 'status':
        return _numericSorting(a, b, !desc)
    default:
        return _stringSorting(a, b, desc)
    }
}

function _stringSorting(a, b, desc) {
    return desc ? `${b}`.toLowerCase().localeCompare(`${a}`.toLowerCase()) : `${a}`.toLowerCase().localeCompare(`${b}`.toLowerCase())
}

function _numericSorting(a, b, desc) {
    return desc ? b - a : a - b
}

function _booleanSorting(a, b, desc) {
    return desc ? Number(b) - Number(a) : Number(a) - Number(b)
}

function _timeSorting(a, b, desc) {
    const timeA = _calcTime(a)
    const timeB = _calcTime(b)

    return desc ? timeB - timeA : timeA - timeB
}

function _calcTime(time) {
    const arr = time.split(':')

    return (arr[0] * 60 * 60) + (arr[1] * 60) + arr[2]
}

function _dateSorting(a, b, desc) {
    return desc ? new Date(b) - new Date(a) : new Date(a) - new Date(b)
}

function copyArray(arr) {
    return JSON.parse(JSON.stringify(arr))
}

function getNoun(number, one, two, five) {
    let n = Math.abs(number)
    n %= 100
    if (n >= 5 && n <= 20) {
        return five
    }
    n %= 10
    if (n === 1) {
        return one
    }
    if (n >= 2 && n <= 4) {
        return two
    }
    return five
}

function localSearch(items, search, searchFieldNames = ['name']) {
    if (!search) {
        return items
    }

    return items.filter((item) => searchFieldNames.some((name) => {
        const value = item[name] ? item[name] : ''

        return value.toString().toLowerCase().includes(search.toLowerCase())
    }))
}

function splitUrlForRef(url) {
    if (url) {
        const [signupUrl] = url.split('/signup')
        return signupUrl
    }
}

export {
    stopPropagationForNotification,
    checkFormValidity,
    collectFormData,
    onlyNumbers,
    scrollToTopById,
    currency,
    copyInStash,
    splitString,
    checkEqualsArrays,
    getNoun,
    localSearch,
    localSort,
    splitUrlForRef,
}
