<template>
    <CustomMenu
        v-model="isOpenPopup"
        hideTransitionSlider
    >
        <template #activator>
            <div
                class="flex items-center gap-x-3.5 px-3 cursor-pointer"
                :class="[ mini && 'justify-center' ]"
                @click="onOpenCloseAccountMenu"
            >
                <UserImage :image="currentUser.avatar_img" />

                <div v-if="!mini">
                    <div class="mb-1 max-w-33.75 text-xs text-gray-370 dark:text-white truncate">
                        {{ userName }}
                    </div>

                    <div class="text-xxxs max-w-33.75 text-gray-200 dark:text-gray-250 truncate">
                        {{ currentUser.email }}
                    </div>
                </div>
            </div>
        </template>

        <template #dropdown>
            <div
                class="w-53.25 absolute left-14 -bottom-11 rounded-1.25 bg-white dark:bg-blue-800 border border-white-500 dark:border-gray-760 shadow-activeMenu dark:shadow-activeMenuDark"
                @click.stop
            >
                <div class="flex items-center gap-x-3.5 pt-2.5 pb-3.5 px-3.75 border-b-1 border-white-500 dark:border-gray-760">
                    <UserImage :image="currentUser.image" />

                    <div>
                        <div class="mb-1 max-w-33.75 truncate text-xs text-gray-370 dark:text-white">
                            {{ userName }}
                        </div>

                        <div class="max-w-33.75 truncate text-xxxs text-gray-250 dark:text-gray-250">
                            {{ currentUser.email }}
                        </div>
                    </div>
                </div>

                <RoutingSideBarAccount @onOpenCloseAccountMenu="onOpenCloseAccountMenu"/>

                <div class="flex items-center gap-x-2.5 px-3 py-3.5 border-t-1 border-white-500 dark:border-gray-760">
                    <ThemeToggle v-model:currentTheme="currentTheme"/>

                    <div class="text-xs text-gray-200">
                        {{ currentTheme }} theme
                    </div>
                </div>
            </div>
        </template>
    </CustomMenu>
</template>

<script setup>
import { computed, ref } from 'vue'

import CustomMenu from '@/components/menu/CustomMenu/CustomMenu.vue'
import UserImage from '@/components/userImage/UserImage.vue'
import ThemeToggle from '@/components/buttons/ThemeToggle.vue'
import RoutingSideBarAccount from '@/components/navigation/RoutingSideBar/RoutingSideBarAccount.vue'

import { useAuthorizationStore } from '@/store/authorizationStore'

defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
})

const authorizationStore = useAuthorizationStore()

const isOpenPopup = ref(false)

const currentTheme = ref('')

const currentUser = computed(() => authorizationStore.user || {})

const userName = computed(() => {
    const { last_name, first_name } = currentUser.value

    return `${last_name} ${first_name}`
})

function onOpenCloseAccountMenu() {
    isOpenPopup.value = !isOpenPopup.value
}
</script>
