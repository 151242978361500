import { ROUTES } from '@/constants/routes'

export default function isAccountDisabled({ next, router, store }) {
    const { blocked = false } = store

    if (!blocked) {
        return router.push({ name: ROUTES.reporting })
    }

    next()
}
