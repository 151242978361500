<template>
    <div class="absolute w-full h-app-bar-bc">
        <div
            ref="mobileBCRef"
            class="relative w-full h-app-bar-bc flex bg-white dark:bg-blue-800 border-gray-100 dark:border-blue-720 transition-all duration-500 custom-scroll-x-w-0"
            :class="breadCrumbsStore.showMobileBC && isMbView ? 'top-app-bar border-b' : '-top-app-bar'"
        >
            <div class="flex items-center grow relative max-w-min pr-10">
                <CustomBreadCrumbs />
            </div>

            <div class="fixed top-app-bar right-15">
                <div class="absolute bg-bc-gradient dark:bg-bc-gradient-dark h-7.5 w-15"></div>
            </div>
        </div>
    </div>

    <div
        class="absolute pr-4 min-mb:pr-18 w-full h-app-bar bg-white dark:bg-blue-800 border-b border-gray-100 dark:border-gray-760"
        :class="wrapperClass"
    >
        <div class="flex w-full justify-between items-center h-full">
            <div
                class="relative h-full flex items-center grow overflow-hidden custom-scroll-x-w-0"
                ref="headerRef"
            >
                <div
                    class="h-full flex items-center cursor-pointer"
                    @click="showSideBars"
                >
                    <MenuIcon class="ml-4 text-gray-370 dark:text-white block min-mb:hidden" />
                </div>

                <div class="flex items-center h-fit">
                    <CustomBreadCrumbs class="hidden min-mb:block" />
                </div>

                <div class="max-mob:hidden sticky top-0 h-app-bc-shadow right-15">
                    <div class="absolute bg-bc-gradient dark:bg-bc-gradient-dark h-full w-15"></div>
                </div>
            </div>

            <div class="flex items-center gap-x-7 max-mob:gap-x-4">
                <AccountBalance :balance="balance"/>

                <div class="flex items-center gap-x-2">
                    <div class="text-xs text-gray-200 dark:text-white font-medium">
                        Invited <span class="max-mob:hidden">Users</span>:
                    </div>

                    <div class="text-xs text-green-600 dark:text-green">{{ invitedUsers }}</div>
                </div>

                <div class="flex items-center gap-x-2">
                    <div class="text-xs text-gray-200 dark:text-white font-medium">Referral link:</div>

                    <div
                        class="flex items-center gap-x-2 py-1 px-2 max-mob:px-1 bg-purple-50 dark:bg-blue-770 rounded-1.25 cursor-pointer"
                        @click="copyUrl"
                    >
                        <div
                            v-if="!isMobView"
                            class="text-sm text-purple dark:text-purple-140 font-medium"
                        >
                            {{ referralLinkForView }}
                        </div>

                        <LinkIcon
                            class="text-purple dark:text-purple-140"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    computed, onUnmounted, ref, watch, inject, onMounted,
} from 'vue'

import AccountBalance from '@/components/accountBalance/AccountBalance.vue'
import MenuIcon from '@/components/icons/MenuIcon.vue'
import CustomBreadCrumbs from '@/components/breadCrumbs/CustomBreadCrumbs.vue'
import LinkIcon from '@/components/icons/LinkIcon.vue'

import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'
import { useHeaderStore } from '@/store/headerStore'
import { useDefaultStore } from '@/store/defaultStore'

import { copyInStash, splitUrlForRef } from '@/helpers/app-helper'

import { viewPorts } from '@/constants/viewPorts'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    marginClass: {
        type: Array,
        default: () => [],
    },
})

const emits = defineEmits(['showSideBars'])

const defaultStore = useDefaultStore()
const breadCrumbsStore = useBreadCrumbsStore()
const headerStore = useHeaderStore()

const currentViewSize = inject('currentViewSize')

const mobileBCRef = ref(null)
const headerRef = ref(null)

const isMobView = computed(() => viewPorts.mob >= currentViewSize.value)

const balance = computed(() => headerStore.balance)
const invitedUsers = computed(() => headerStore.invitedUsers)
const referralLinkForCopy = computed(() => headerStore.referralLink)
const referralLinkForView = computed(() => splitUrlForRef(referralLinkForCopy.value))

const headerInterval = ref(null)

const wrapperClass = computed(() => [
    ...props.marginClass,
    props.mini && 'min-mb:w-app-bar-min',
    !props.mini && 'min-mb:w-app-bar-max',
])

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

function showSideBars() {
    emits('showSideBars')
}

function setHeaderInterval() {
    clearInterval(headerInterval.value)

    headerStore.goToLoadHeader()

    headerInterval.value = setInterval(() => {
        headerStore.goToLoadHeader()
    }, 60000)
}

function copyUrl() {
    copyInStash(referralLinkForCopy.value)

    defaultStore.setInfoMessage({ message: 'Successful copy' })
}

function scrollToRight() {
    setTimeout(() => {
        if (headerRef.value) {
            headerRef.value.scrollTo({ top: 0, left: 9999, behavior: 'smooth' })
        }

        if (mobileBCRef.value) {
            mobileBCRef.value.scrollTo({ top: 0, left: 9999, behavior: 'smooth' })
        }
    }, 100)
}

function scrollToRightMobileBC() {
    setTimeout(() => {
        mobileBCRef.value.scrollTo({ top: 0, left: 9999, behavior: 'smooth' })
    }, 100)
}

onMounted(setHeaderInterval)

watch(() => breadCrumbsStore.items, () => {
    scrollToRight()
    scrollToRightMobileBC()
}, { deep: true })

onUnmounted(() => {
    clearInterval(headerInterval.value)
})
</script>
